import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { Normalize } from "styled-normalize";
import GlobalStyle from "../styles/GlobalStyles";

const theme = {
    color: {
        text: 'green'
    },
    background: {
        image:  "../images/CHINY.png",
    }
}

function Layout({ children, pageTitle }) {
  return (
      <ThemeProvider theme={theme}>
      <Div>
        <Normalize />
        <GlobalStyle />
        {/* <title>{pageTitle}</title> */}
        {children}
      </Div>
    </ThemeProvider>
  );
}

export default Layout;

export const Div = styled.div`
   //scroll-snap-type: y mandatory;
   overflow-y: hidden;
   overflow-x: hidden;
   min-height: 100vh;
   scroll-behavior: smooth;
;`;
 