import { createGlobalStyle } from "styled-components";
import quando from "../fonts/Quando-Regular.woff";
import poppins from "../fonts/Poppins-Regular.ttf";
import ovo from "../fonts/Ovo-Regular.ttf";
import piazzolla from "../fonts/Piazzolla-Light.ttf";

const GlobalStyles = createGlobalStyle`
  :root {
    --black: #000000;
    --greymi: #ddd;
    --litegreen: #9CB783;
    --green: #4D7853;
    --greenrgb: 77, 120, 83;
    --darkgreen: #425E4B;
    --yellow: #ff8800;
    --offwhite: #e6e6e6;
    --offwhitergba: 242, 242, 242;
    --white: #fff;
    --purple: #6750A4;
    --gray: #1A422B;

    --primaryRed: #DD0426;
    --secondaryRed: #F02D3A;
    --darkGrey: #252627;
    --lightGrey: #D3D4D9;
    --snow: #FFF9FB;
  }

  @font-face {
    font-family: 'Quando';
    font-style: normal;
    font-weight: 400;
    src: url(${quando});
    font-display: fallback;
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url(${poppins});
    font-display: fallback;
  }

  @font-face {
    font-family: 'Ovo';
    font-style: normal;
    font-weight: 400;
    src: url(${ovo});
    font-display: fallback;
  }

  @font-face {
    font-family: 'Piazzolla';
    font-style: normal;
    font-weight: 400;
    src: url(${piazzolla});
    font-display: fallback;
  }

  html {
    background: black;
    color: var(--black);
    box-sizing: border-box;
    font-size: clamp(0.95rem, 1.8vw, 2.5rem);
    line-height: 1.6em;
    letter-spacing: 1px;
    @media (max-width: 320px) and (max-height: 671px) {
      font-size: 0.7rem;
    }
  }
  
  *, *::before, *::after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
  }

  h1,h2,h3,h4,h5,h6,h7,p {
    margin: 0;
  } 

  body, * {
    margin: 0;
    padding: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    color: var(---black)
  }

  img {
    max-width: 100%;
    //max-height: 100%;
  }

  a {
    text-decoration: none;
    transition: opacity 0.2s ease-out;
    color: var(--white);
    font-weight: 500;
  }

  a:hover {
    opacity: 0.69;
    color: var(--yellow) !important;
  }

  ul {
    margin: 0 !important;
    padding: 0;
    list-style: none;
  }

  section {
    min-height: 800px;
    display: flex;
    font-family: "Piazzolla", sans-serif;
    //scroll-snap-align: start;
    @media (max-width: 767px) and (orientation: portrait) {
      min-height: 677px;
    }
    .text-box {
      min-height: 500px;
    }
    .text-box > * {
      margin: 1.1em 0;
    }
  }
`;

export default GlobalStyles;


