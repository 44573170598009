import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../../components/Layout";

function Blog({ data }) {
  return (
    <Layout>

      <h1>here are links to all my posts</h1>
      <ul>
        {data.allMdx.nodes.map(node => (
            <Link key={node.id} to={node.slug}>
                <h2>{node.frontmatter.title}</h2>
                <p>post created on {node.frontmatter.date}</p>
                <hr />
            </Link>
        ))}
      </ul>
    </Layout>
    
  );
}

export const query = graphql`
  query QueryPostTitle {
    allMdx {
      nodes {
        frontmatter {
          date
          title
        }
        id
        slug
      }
    }
  }
`;

export default Blog;
